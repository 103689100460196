import {lazy, Suspense} from 'react';
import {Outlet} from 'react-router-dom';
// Guards
import {AuthGuard, RoleBasedGuard, FeatureGuard, OnboardingGuard} from 'src/guards';
// Layouts
import DashboardLayout from 'src/layouts/app';
// Components
import {LoadingScreen} from 'src/components/loading-screen';
// Roles & Paths
import {paths} from 'src/routes/paths';
import {roles, routeRoles} from 'src/utils/rolesConfig';
// Context
import {AuthProvider} from 'src/contexts/auth-context';
import {UserDetailsProvider} from 'src/contexts/user-details-context';

// Lazy-loaded Pages
const OnboardingPage = lazy(() => import('src/pages/app/onboarding/view/OnboardingPage'));
const DashboardPage = lazy(() => import('src/pages/app/dashboard/view/DashboardPage'));
const ReportingPage = lazy(() => import('src/pages/app/reporting/view/ReportingPage'));
const TransactionsPage = lazy(() => import('src/pages/app/gift-voucher/transactions/view/TransactionsPage'));
const UserAccountPage = lazy(() => import('src/pages/app/account/view/UserAccountPage'));
const CompanyPage = lazy(() => import('src/pages/app/company/view/CompanyPage'));
const AvailabilityPage = lazy(() => import('src/pages/app/booking/view/AvailabilityPage'));
const UsersPage = lazy(() => import('src/pages/app/users/view/UsersPage'));
const InvoicesPage = lazy(() => import('src/pages/app/invoices/view/InvoicesPage'));
const GiftVoucherTypes = lazy(() => import('src/pages/app/gift-voucher/types/views/TypesPage'));
const GiftVouchersTypeCreate = lazy(() => import('src/pages/app/gift-voucher/types/views/TypeCreatePage'));
const GiftVouchersTypeEdit = lazy(() => import('src/pages/app/gift-voucher/types/views/TypeEditPage'));
const GiftVouchersManage = lazy(() => import('src/pages/app/gift-voucher/manage/view/ManagePage'));
const GiftVouchersDetails = lazy(() => import('src/pages/app/gift-voucher/details/view/DetailsPage'));
const GiftVouchersSearch = lazy(() => import('src/pages/app/gift-voucher/search/view/SearchPage'));
// Customers
const CustomersListPage = lazy(() => import('src/pages/app/customers/view/CustomersListPage'));
// Employees
const EmployeesListPage = lazy(() => import('src/pages/app/employees/view/EmployeesListPage'));
// Wellness
const LandingPage = lazy(() => import('src/pages/app/wellness/landing/view/LandingPage'));
const SpacesListPage = lazy(() => import('src/pages/app/wellness/spaces/view/SpacesListPage'));
const ServicesListPage = lazy(() => import('src/pages/app/wellness/services/view/ServicesListPage'));
const CalendarPage = lazy(() => import('src/pages/app/wellness/calendar/view/CalendarPage'));

export const appRoutes = [
  {
    path: '/dashboard',
    element: (
      <AuthProvider>
        <UserDetailsProvider>
          <AuthGuard>
            <RoleBasedGuard roles={routeRoles[paths.app.root]}>
              <OnboardingGuard requireOnboardingCompleted={true}>
                <DashboardLayout>
                  <Suspense fallback={<LoadingScreen />}>
                    <Outlet />
                  </Suspense>
                </DashboardLayout>
              </OnboardingGuard>
            </RoleBasedGuard>
          </AuthGuard>
        </UserDetailsProvider>
      </AuthProvider>
    ),
    children: [
      // Routes publiques (pas besoin de guards supplémentaires)
      {
        path: 'onboarding',
        element: <OnboardingGuard requireOnboardingCompleted={false}><OnboardingPage /></OnboardingGuard>,
      },
      {
        path: '',
        element: <DashboardPage />,
        index: true,
      },
      {
        path: 'user-account',
        element: <UserAccountPage />,
      },

      // Routes COMPANY_ADMIN
      {
        element: <RoleBasedGuard roles={[roles.COMPANY_ADMIN]}><Outlet /></RoleBasedGuard>,
        children: [
          {path: 'reporting', element: <ReportingPage />},
          {path: 'company', element: <CompanyPage />},
          {path: 'availability', element: <AvailabilityPage />},
          {path: 'users', element: <UsersPage />},
          {path: 'invoices', element: <InvoicesPage />},

          // Section gift-voucher
          {
            path: 'gift-voucher',
            children: [
              {index: true, element: <GiftVoucherTypes />},
              {path: 'types', element: <GiftVoucherTypes />},
              {path: 'types/create/:type', element: <GiftVouchersTypeCreate />},
              {path: 'types/edit/:type/:id', element: <GiftVouchersTypeEdit />},
              {path: 'manage', element: <GiftVouchersManage />},
              {path: 'transactions', element: <TransactionsPage />},
            ],
          },

          {
            path: 'customers',
            element: <Outlet />,
            children: [
              {index: true, element: <CustomersListPage />},
            ],
          },

          {
            path: 'employees',
            element: <FeatureGuard feature="employees"><Outlet /></FeatureGuard>,
            children: [
              {index: true, element: <EmployeesListPage />},
            ],
          },

          // Section wellness avec FeatureGuard
          {
            path: 'wellness',
            element: <FeatureGuard feature="wellness"><Outlet /></FeatureGuard>,
            children: [
              {index: true, element: <LandingPage />},
              {path: 'spaces', element: <SpacesListPage />},
              {path: 'services', element: <ServicesListPage />},
              {path: 'calendar', element: <CalendarPage />},
            ],
          },
        ],
      },

      // Routes publiques qui doivent rester accessibles
      {path: 'gift-voucher/details/:id', element: <GiftVouchersDetails />},
      {path: 'gift-voucher/search', element: <GiftVouchersSearch />},
    ],
  },
];
