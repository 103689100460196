import {useMemo} from 'react';
import {paths} from 'src/routes/paths';
import {Icon} from '@iconify/react';
import {useUserDetailsContext} from 'src/hooks';
import {roles} from 'src/utils/rolesConfig';

export function useNavData() {
  const {userDetails} = useUserDetailsContext();
  const currentCompany = userDetails?.companyDetails?.[0] || null;
  const isOnboardingCompleted = currentCompany?.onboardingCompleted;
  const userRole = userDetails?.role;

  const features = useMemo(() =>
    currentCompany?.features || {},
  [currentCompany],
  );

  const data = useMemo(() => {
    const baseMenu = [];

    if (!isOnboardingCompleted) {
      baseMenu.push({
        subheader: 'Bienvenue',
        items: [
          {
            title: 'Configuration initiale',
            path: paths.onboarding.root,
            icon: <Icon icon="mdi:progress-check" height="24" />,
          },
        ],
      });
    }

    if (isOnboardingCompleted) {
      baseMenu.push({
        subheader: 'tableau de bord',
        items: [
          {
            title: 'Dashboard',
            path: paths.app.dashboard,
            icon: <Icon icon="clarity:dashboard-line" height="24" />,
          },
          ...(userRole === roles.COMPANY_ADMIN ? [{
            title: 'Rapports',
            path: paths.app.reporting,
            icon: <Icon icon="fluent-mdl2:report-document" height="24" />,
          }] : []),
        ],
      });

      if (userRole === roles.COMPANY_ADMIN) {
        baseMenu.push({
          subheader: 'Bons cadeaux',
          items: [
            {
              title: 'Catalogue',
              path: paths.app.giftVoucher.types.list,
              icon: <Icon icon="solar:clipboard-list-linear" height="24" />,
            },
            {
              title: 'Ventes',
              path: paths.app.giftVoucher.manage,
              icon: <Icon icon="solar:gift-outline" height="24" />,
            },
            {
              title: 'Recherche',
              path: paths.app.giftVoucher.search,
              icon: <Icon icon="solar:bill-check-linear" height="24" />,
            },
            {
              title: 'Transactions',
              path: paths.app.giftVoucher.transactions,
              icon: <Icon icon="uil:transaction" height="24" />,
            },
          ],
        });
      } else {
        baseMenu.push({
          subheader: 'Bons cadeaux',
          items: [
            {
              title: 'Recherche',
              path: paths.app.giftVoucher.search,
              icon: <Icon icon="solar:bill-check-linear" height="24" />,
            },
          ],
        });
      }

      if (userRole === roles.COMPANY_ADMIN) {
        baseMenu.push({
          subheader: 'Administration',
          items: [
            {
              title: 'Mon établissement',
              path: paths.app.company,
              icon: <Icon icon="mdi:company" height="24" />,
            },
            {
              title: 'Utilisateurs',
              path: paths.app.users,
              icon: <Icon icon="ph:users-three" height="24" />,
            },
            {
              title: 'Factures',
              path: paths.app.invoices,
              icon: <Icon icon="iconamoon:invoice-light" height="24" />,
            },
          ],
        });

        if (features.wellness) {
          baseMenu.push({
            subheader: 'Bien-être',
            items: [
              {
                title: 'Espaces',
                path: paths.app.wellness.spaces.list,
                icon: <Icon icon="material-symbols:spa-outline" height="24" />,
              },
              {
                title: 'Services',
                path: paths.app.wellness.services.list,
                icon: <Icon icon="carbon:catalog" height="24" />,
              },
              {
                title: 'Clients',
                path: paths.app.customers.list,
                icon: <Icon icon="ph:users-three" height="24" />,
              },
              {
                title: 'Calendrier',
                path: paths.app.wellness.calendar.root,
                icon: <Icon icon="carbon:calendar" height="24" />,
              },
            ],
          });
        }

        if (features.employees) {
          baseMenu.push({
            subheader: 'Équipe',
            items: [
              {
                title: 'Employés',
                path: paths.app.employees.list,
                icon: <Icon icon="ph:users-three" height="24" />,
              },
            ],
          });
        }
      }
    }

    return baseMenu;
  }, [isOnboardingCompleted, userRole, features]);

  return data;
}
