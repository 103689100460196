import {m} from 'framer-motion';
// @mui
import RefreshIcon from '@mui/icons-material/Refresh';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
// assets
import {MaintenanceIllustration} from 'src/assets/illustrations';
// components
import {RouterLink} from 'src/routes/components';
import {MotionContainer, varBounce} from 'src/components/animate';

// ----------------------------------------------------------------------

/**
 * MaintenancePage Component
 *
 * Ce composant affiche une page de maintenance.
 * Il est utilisé pour informer l'utilisateur que le site est temporairement en maintenance.
 *
 * La page comprend un message de maintenance plus humoristique, une illustration,
 * et un bouton pour retourner à la page d'accueil une fois que la maintenance est terminée.
 *
 * @return {React.ReactElement} L'élément React représentant la page de maintenance.
 */
export default function MaintenancePage() {
  return (
    <MotionContainer>
      <m.div variants={varBounce().in}>
        <Typography variant="h3" sx={{mb: 2}}>
          Oups, un petit coup de tournevis !
        </Typography>
      </m.div>

      <m.div variants={varBounce().in}>
        <Typography sx={{color: 'text.secondary'}}>
          On fait un peu de ménage sous le capot. 🧰<br />
          Promis, ça ne prendra pas trop longtemps !<br />
          Tu peux revenir dans quelques instants pour voir si tout est réparé. 🛠️
        </Typography>
      </m.div>

      <m.div variants={varBounce().in}>
        <MaintenanceIllustration sx={{height: 260, my: {xs: 5, sm: 10}}} />
      </m.div>

      <Button component={RouterLink} href="/" size="large" variant="contained" startIcon={<RefreshIcon />}>
  Rafraichir la page
      </Button>
    </MotionContainer>
  );
}
