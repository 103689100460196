import {m} from 'framer-motion';
// @mui
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
// assets
import {ForbiddenIllustration} from 'src/assets/illustrations';
// components
import {RouterLink} from 'src/routes/components';
import {MotionContainer, varBounce} from 'src/components/animate';

// ----------------------------------------------------------------------

/**
 * View403 Component
 *
 * Ce composant affiche une page d'erreur '403 Accès Interdit'.
 * Il est utilisé pour informer l'utilisateur qu'il n'a pas les droits nécessaires pour accéder à une page ou une ressource spécifique.
 *
 * La page comprend un message d'erreur, une illustration représentant l'accès interdit,
 * et un bouton pour retourner à la page d'accueil.
 *
 * @return {React.ReactElement} L'élément React représentant la page d'erreur 403.
 *
 * @example
 * Utilisation dans un routeur ou un autre composant pour afficher la page 403 en cas d'accès interdit
 * <Route path="/403" element={<View403 />} />
 */
export default function View403() {
  return (
    <MotionContainer>
      <m.div variants={varBounce().in}>
        <Typography variant="h3" sx={{mb: 2}}>
          Accès Interdit !
        </Typography>
      </m.div>

      <m.div variants={varBounce().in}>
        <Typography sx={{color: 'text.secondary'}}>
      Il semble que vous n&apos;avez pas les accès nécessaires pour accéder à cette page.
        </Typography>
      </m.div>


      <m.div variants={varBounce().in}>
        <ForbiddenIllustration sx={{height: 260, my: {xs: 5, sm: 10}}} />
      </m.div>

      <Button component={RouterLink} href="/" size="large" variant="contained">
        Retour
      </Button>
    </MotionContainer>
  );
}
