import {Box, Container, Typography, Button, Paper, alpha} from '@mui/material';
import {ForbiddenIllustration} from 'src/assets/illustrations';
import {RouterLink} from 'src/routes/components';

export default function ViewFeatureError({feature, missingDependencies = []}) {
  const messages = {
    wellness: {
      module: 'Module Bien-être',
      title: 'Accès non disponible',
      description: 'Vous n\'avez pas accès à cette fonctionnalité. Pour plus d\'informations, contactez le support.',
    },
    employees: {
      module: 'Module Gestion des employés',
      title: 'Accès non disponible',
      description: 'Vous n\'avez pas accès à cette fonctionnalité. Pour plus d\'informations, contactez le support.',
    },
    default: {
      module: 'Module',
      title: 'Accès non disponible',
      description: 'Vous n\'avez pas accès à cette fonctionnalité. Pour plus d\'informations, contactez le support.',
    },
  };

  const currentMessage = messages[feature] || messages.default;

  return (
    <Box
      sx={{
        height: 1,
        display: 'flex',
        alignItems: 'center',
        bgcolor: 'background.neutral',
      }}
    >
      <Container maxWidth="lg">
        <Paper
          sx={{
            display: 'flex',
            flexDirection: {xs: 'column', md: 'row'},
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: {xs: 4, md: 8},
            p: {xs: 3, md: 6},
            bgcolor: (theme) => theme.palette.mode === 'light' ? 'grey.50' : 'background.neutral',
            borderRadius: 3,
            boxShadow: (theme) => `0 0 24px ${alpha(theme.palette.primary.main, 0.08)}`,
          }}
          elevation={0}
        >
          {/* Section Gauche - Contenu */}
          <Box sx={{flex: 1, maxWidth: 480}}>
            <Typography
              variant="h6"
              sx={{
                color: 'primary.main',
                mb: 1,
                fontWeight: 500,
              }}
            >
              {currentMessage.module}
            </Typography>

            <Typography
              variant="h3"
              sx={{
                fontWeight: 700,
                color: 'text.primary',
                mb: 2,
                letterSpacing: '-0.02em',
              }}
            >
              {currentMessage.title}
            </Typography>

            <Typography
              variant="body1"
              sx={{
                color: 'text.secondary',
                mb: 4,
                lineHeight: 1.6,
              }}
            >
              {currentMessage.description}
            </Typography>

            {missingDependencies.length > 0 && (
              <Box sx={{mb: 4}}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: 'text.primary',
                    mb: 2,
                    fontWeight: 600,
                  }}
                >
                  Modules requis pour accéder à cette fonctionnalité
                </Typography>

                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 1.5,
                  }}
                >
                  {missingDependencies.map((dep) => (
                    <Box
                      key={dep}
                      sx={{
                        px: 2,
                        py: 1.5,
                        bgcolor: (theme) => alpha(theme.palette.error.main, 0.04),
                        border: '1px solid',
                        borderColor: 'error.light',
                        borderRadius: 2,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Box
                        sx={{
                          width: 6,
                          height: 6,
                          borderRadius: '50%',
                          bgcolor: 'error.main',
                          mr: 1.5,
                        }}
                      />
                      <Typography
                        sx={{
                          color: 'error.dark',
                          fontSize: '0.9rem',
                          fontWeight: 500,
                        }}
                      >
                        {messages[dep]?.module || dep}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            )}

            <Button
              component={RouterLink}
              href="/dashboard"
              size="large"
              variant="contained"
              sx={{
                'px': 4,
                'py': 1.5,
                'fontSize': '0.95rem',
                'fontWeight': 600,
                'textTransform': 'none',
                'boxShadow': (theme) => `0 8px 16px ${alpha(theme.palette.primary.main, 0.24)}`,
                '&:hover': {
                  boxShadow: (theme) => `0 10px 20px ${alpha(theme.palette.primary.main, 0.32)}`,
                },
              }}
            >
              Retour au tableau de bord
            </Button>
          </Box>

          {/* Section Droite - Illustration */}
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ForbiddenIllustration
              sx={{
                width: '100%',
                maxWidth: 400,
                opacity: 0.9,
              }}
            />
          </Box>
        </Paper>
      </Container>
    </Box>
  );
}
