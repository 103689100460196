import {m} from 'framer-motion';
// @mui
import {Typography, Button} from '@mui/material';
// routes
import {RouterLink} from 'src/routes/components';
// components
import {MotionContainer, varBounce} from 'src/components/animate';
// assets
import {PageNotFoundIllustration} from 'src/assets/illustrations';

// ----------------------------------------------------------------------

export default function View404() {
  return (
    <MotionContainer sx={{py: 0}}>
      <m.div variants={varBounce().in}>
        <Typography variant="h3" sx={{mb: 2}}>
            Désolé, page introuvable !
        </Typography>
      </m.div>

      <m.div variants={varBounce().in}>
        <Typography sx={{color: 'text.secondary'}}>
            Nous ne trouvons pas la page que vous cherchez.
        </Typography>
      </m.div>

      <m.div variants={varBounce().in}>
        <PageNotFoundIllustration
          sx={{
            height: 260,
            my: {xs: 5, sm: 10},
          }}
        />
      </m.div>

      <Button component={RouterLink} href="/" size="large" variant="contained" color="primary">
          Retour à l&apos;accueil
      </Button>
    </MotionContainer>
  );
}
